<template>
  <v-select
    dense
    outlined
    return-object
    item-text="name"
    v-model="type"
    :items="typeList"
    :label="$t('maintenanceTask.type')"
    prepend-inner-icon="mdi-progress-check"
  ></v-select>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';

const FIELD_NAME = 'type';

export default {
  name: 'TypeSelection',
  data() {
    return {
      typeList: [{
        name: this.$t('maintenanceTask.allTypes'),
        value: 'All',
      }, {
        name: this.$t('maintenanceTask.TBM'),
        value: 'TBM',
      }, {
        name: this.$t('maintenanceTask.CBM'),
        value: 'CBM',
      }, {
        name: this.$t('maintenanceTask.PM'),
        value: 'PM',
      }],
    };
  },
  computed: {
    ...mapGetters('webApp', ['filters']),
    isTypeFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    type: {
      get() {
        const typeFilter = this.filters && this.filters[FIELD_NAME];
        if (typeFilter) {
          const value = this.typeList.find((s) => s.value === typeFilter.value);
          if (value) {
            return value;
          }
        }
        return this.typeList[0];
      },
      set(typeVal) {
        this.setTypeFilter(typeVal);
      },
    },
  },
  created() {
    if (this.isTypeFilterInactive) {
      this.setTypeFilter(this.typeList[0]);
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    setTypeFilter(type) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: type.value,
          operation: 'eq',
        },
      });
    },
  },
};
</script>

<template>
  <v-card flat>
    <v-progress-linear
      :indeterminate="true"
      v-if="loading"
    ></v-progress-linear>
    <template v-else-if="totalCount && !loading">
      <v-card-text>
        <v-tabs
          v-model="tab"
        >
          <v-tab
            :key="i"
            class="text-none"
            v-for="(groupData, groupKey, i) in taskDetails"
          >
            {{ groupKey }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="i"
            v-for="(groupData, groupKey, i) in taskDetails"
          >
            <v-data-table
              fixed-header
              item-key="_id"
              :headers="headers"
              :loading="loading"
              disable-pagination
              :items="groupData.values"
              hide-default-footer
            >
              <!-- eslint-disable-next-line -->
              <template #item.result="{ item }">
                <v-radio-group
                  row
                  v-model="item.result"
                  :disabled="(item.islimited && item.type === 'value')
                    || task.status !== 'in progress'
                    || saving"
                  :mandatory="false"
                >
                  <v-radio
                    color="success"
                    label="OK"
                    value="OK"
                  ></v-radio>
                  <v-radio
                    color="error"
                    label="NG"
                    value="NG"
                  ></v-radio>
                </v-radio-group>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.value="{ item }">
                <v-edit-dialog
                  large
                  :return-value.sync="item.value"
                  @save="getResult(item)"
                >
                  {{ item.value }}
                  <v-icon
                    small
                    color="primary"
                    :disabled="task.status !== 'in progress' || saving"
                  >
                    mdi-pencil
                  </v-icon>
                  <template #input>
                    <v-text-field
                      :disabled="task.status !== 'in progress'"
                      v-model="item.value"
                      label="Edit"
                      single-line
                      :type="item.type === 'value' ? 'number' : 'text'"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="updateTaskDetails"
          class="text-none"
          :loading="saving"
          :disabled="task.status !== 'in progress'"
        >
          {{ $t('maintenanceTask.save') }}
        </v-btn>
      </v-card-actions>
    </template>
    <v-card-text v-else>
      {{ $t('maintenanceTask.noChecklist') }}
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';

export default {
  name: 'TaskChecklist',
  data() {
    return {
      loading: false,
      saving: false,
      tab: 0,
      headers: [
        {
          text: this.$t('maintenanceTask.solutionName'),
          value: 'solutiondetailname',
        },
        {
          text: this.$t('maintenanceTask.description'),
          value: 'description',
        },
        {
          text: this.$t('maintenanceTask.type'),
          value: 'type',
        },
        {
          text: this.$t('maintenanceTask.upper'),
          value: 'upper',
        },
        {
          text: this.$t('maintenanceTask.lower'),
          value: 'lower',
        },
        {
          text: this.$t('maintenanceTask.value'),
          value: 'value',
        },
        {
          text: this.$t('maintenanceTask.result'),
          value: 'result',
        },
      ],
      taskDetails: [],
    };
  },
  created() {
    this.getTaskDetails();
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask', 'taskChecklist']),
    task() {
      return this.selectedTask[0];
    },
    totalCount() {
      return (this.taskChecklist && this.taskChecklist.totalCount) || 0;
    },
  },
  methods: {
    ...mapActions('maintenanceTask', [
      'fetchTaskChecklist',
      'updateTaskChecklistById',
    ]),
    async getTaskDetails() {
      this.loading = true;
      const list = await this.fetchTaskChecklist(this.task.id);
      if (list) {
        this.taskDetails = this.taskChecklist.results
          .reduce((result, currentValue) => {
            const key = currentValue.group;
            if (!result[key]) {
              result[key] = {};
              result[key].values = [];
            }
            result[key].values = [
              ...result[key].values,
              {
                result: '',
                ...currentValue,
              },
            ];
            return result;
          }, {});
      }
      this.loading = false;
    },
    getResult(item) {
      if (item.type === 'value' && item.islimited) {
        if (item.value > item.upper || item.value < item.lower) {
          item.result = 'NG';
        } else {
          item.result = 'OK';
        }
      }
    },
    async updateTaskDetails() {
      this.saving = true;
      const payload = [];
      Object.keys(this.taskDetails).forEach((key) => {
        const values = this.taskDetails[key].values.map(({ _id, value, result }) => ({
          id: _id,
          value,
          result,
        }));
        payload.push(...values);
      });
      const updated = await Promise.all(payload.map(async ({ id, ...p }) => {
        const result = await this.updateTaskChecklistById({
          id,
          payload: { ...p },
        });
        return result;
      }));
      if (updated.every((u) => u)) {
        this.getTaskDetails();
      }
      this.saving = false;
    },
  },
};
</script>

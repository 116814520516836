<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <add-sparepart />
    </v-card-title>
    <v-card-text>
      <v-data-table
        fixed-header
        item-key="_id"
        :headers="headers"
        :loading="loading"
        disable-pagination
        :items="taskSpareparts"
        hide-default-footer
      >
        <!-- eslint-disable-next-line -->
        <template #item.sparepartname="{ item }">
          <div>
            {{ item.sparepartname }}
          </div>
          <div>
            {{ item.sparepartcode }}
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.action="{ item }">
          <edit-sparepart :selectedPart="item" />
          <delete-sparepart
            :id="item._id"
            :name="item.sparepartname"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import AddSparepart from './AddSparepart.vue';
import EditSparepart from './EditSparepart.vue';
import DeleteSparepart from './DeleteSparepart.vue';

export default {
  name: 'TaskSpareparts',
  components: {
    AddSparepart,
    EditSparepart,
    DeleteSparepart,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t('maintenancePlan.sparepartName'), value: 'sparepartname' },
        { text: this.$t('maintenancePlan.machinePosition'), value: 'machinepositionname' },
        { text: this.$t('maintenanceTask.quantity'), value: 'quantity' },
        {
          text: this.$t('maintenancePlan.action'),
          value: 'action',
          sortable: false,
          width: '20%',
        },
      ],
    };
  },
  async created() {
    this.loading = true;
    await this.fetchTaskSpareparts(this.task.id);
    this.loading = false;
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask', 'taskSpareparts']),
    task() {
      return this.selectedTask[0];
    },
  },
  methods: {
    ...mapActions('maintenanceTask', ['fetchTaskSpareparts']),
  },
};
</script>

<template>
  <div style="height:100%">
    <template v-if="loading">
      <task-loading />
    </template>
    <template v-else-if="error">
      <task-error />
    </template>
    <template v-else-if="!error && !task">
      <task-no-records />
    </template>
    <template v-else>
      <div
        :key="i"
        :class="i !== 0 ? 'mt-8 mb-2' : 'mb-2'"
        class="headline ml-4 font-weight-medium"
        v-for="(groupData, groupKey, i) in task"
      >
        <div class="primary--text" v-if="component === 'status-view'">
          {{ $t(`maintenanceTask.${getTaskStatus(groupKey).text}`) }}
        </div>
        <div class="primary--text" v-else>
          {{ groupKey }}
        </div>
        <component :is="component" :items="groupData.values" />
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import TaskLoading from '../TaskLoading.vue';
import TaskError from '../TaskError.vue';
import TaskNoRecords from '../TaskNoRecords.vue';
import MachineView from '../data-table/MachineView.vue';
import TypeView from '../data-table/TypeView.vue';
import StatusView from '../data-table/StatusView.vue';

export default {
  name: 'TaskList',
  components: {
    TaskLoading,
    TaskError,
    TaskNoRecords,
    MachineView,
    TypeView,
    StatusView,
  },
  computed: {
    ...mapState('maintenanceTask', [
      'loading',
      'error',
    ]),
    ...mapGetters('maintenanceTask', ['task', 'taskStatus']),
    ...mapGetters('webApp', ['group']),
    component() {
      const [key] = this.group;
      let component = '';
      if (key === 'machinename') {
        component = 'machine-view';
      } else if (key === 'type') {
        component = 'type-view';
      } else if (key === 'status') {
        component = 'status-view';
      }
      return component;
    },
  },
  methods: {
    ...mapActions('maintenanceTask', ['fetchTaskList']),
    getTaskStatus(status) {
      return this.taskStatus(status);
    },
  },
  created() {
    this.fetchTaskList();
  },
};
</script>

<template>
  <v-card
    outlined
    rounded="lg"
  >
    <v-card-title primary-title>
      {{ $t('maintenanceTask.operatorList') }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        clearable
        filled
        multiple
        return-object
        :loading="loading"
        :disabled="saving || invalidStatus"
        :items="operatorList"
        item-text="operatorname"
        item-value="operatorid"
        v-model="taskOperators"
      >
        <template #selection="{ attrs, selected, select, item }">
          <v-chip
            close
            close-icon="mdi-close-circle-outline"
            v-bind="attrs"
            :input-value="selected"
            @click="select"
            @click:close="remove(item)"
          >
            <v-avatar left>
              <v-icon
                color="primary"
                v-text="'mdi-account'"
              ></v-icon>
            </v-avatar>
            {{ item.operatorname }}
          </v-chip>
        </template>
        <template #item="{ item }">
          <v-list-item-avatar>
            <v-icon
              color="primary"
              v-text="'mdi-account'"
            ></v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.operatorname"></v-list-item-title>
            <v-list-item-subtitle v-text="item.operatorcode"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="updateTaskOperators"
        class="text-none"
        :disabled="disableApply || loading"
        :loading="saving"
      >
        {{ $t('maintenanceTask.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'OperatorList',
  data() {
    return {
      saving: false,
      loading: false,
      oldTaskOperators: [],
      taskOperators: [],
    };
  },
  async created() {
    this.loading = true;
    await this.fetchOperators();
    this.taskOperators = await this.fetchTaskOperators(this.task.id);
    this.oldTaskOperators = [...this.taskOperators];
    this.loading = false;
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask']),
    ...mapGetters('maintenanceTask', ['operatorList']),
    task() {
      return this.selectedTask[0];
    },
    invalidStatus() {
      return this.task.status === 'in progress'
        || this.task.status === 'completed';
    },
    disableApply() {
      const oldOps = this.oldTaskOperators.map((o) => o.operatorid);
      const ops = this.taskOperators.map((o) => o.operatorid);
      return JSON.stringify(ops) === JSON.stringify(oldOps) || this.invalidStatus;
    },
  },
  methods: {
    ...mapMutations('helper', [
      'setAlert',
    ]),
    ...mapActions('maintenanceTask', [
      'fetchOperators',
      'fetchTaskOperators',
      'createTaskOperators',
      'deleteTaskOperators',
    ]),
    remove(item) {
      const index = this.taskOperators.findIndex((o) => o.operatorid === item.operatorid);
      if (index >= 0) {
        this.taskOperators.splice(index, 1);
      }
    },
    async updateTaskOperators() {
      this.saving = true;
      const payload = this.taskOperators.map(({ operatorid }) => ({
        assetid: 0,
        operatorid,
        taskid: this.task.id,
        planname: this.task.planname,
        planid: this.task.planid,
        operatorname: this.operatorList.find((o) => o.operatorid === operatorid).operatorname,
      }));
      const deleted = await this.deleteTaskOperators({
        taskId: this.task.id,
      });
      let created = true;
      if (deleted && payload && payload.length) {
        created = await this.createTaskOperators(payload);
      }
      if (deleted && created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'TASK_UPDATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'TASK_UPDATED',
        });
      }
      this.saving = false;
    },
  },
};
</script>

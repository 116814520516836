<template>
  <v-card
    outlined
    rounded="lg"
  >
    <v-card-title>
      <span class="title">
        {{ $t('maintenanceTask.taskReporting') }}
      </span>
      &nbsp;&nbsp;
      <span class="caption" v-if="totalCount">
        ({{ $t('maintenanceTask.checklistCount', { open: openItems, total: totalCount }) }})
      </span>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        color="primary"
        @click="startTask"
        :loading="startLoading"
        :disabled="disableStart"
      >
        <v-icon left>mdi-play</v-icon>
        {{ $t('maintenanceTask.start') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="completeTask"
        :loading="completeLoading"
        :disabled="disableComplete"
      >
        <v-icon left>mdi-stop</v-icon>
        {{ $t('maintenanceTask.complete') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-tabs
        grow
        center-active
        show-arrows
        v-model="tab"
      >
        <v-tab
          :key="i"
          v-for="(item, i) in items"
        >
          {{ $t(`maintenanceTask.${item}`) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="i"
          v-for="(item, i) in items"
        >
          <task-checklist
            v-if="item === 'checklist'"
          />
          <task-spareparts
            v-else-if="item === 'spareparts'"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import WMSService from '@shopworx/services/api/wms.service';
import TaskChecklist from './TaskChecklist.vue';
import TaskSpareparts from './TaskSpareparts.vue';

export default {
  name: 'TaskReporting',
  components: {
    TaskChecklist,
    TaskSpareparts,
  },
  data() {
    return {
      tab: 0,
      startLoading: false,
      completeLoading: false,
      items: ['checklist', 'spareparts'],
    };
  },
  computed: {
    ...mapState('maintenanceTask', [
      'selectedTask',
      'taskChecklist',
      'taskSpareparts',
    ]),
    ...mapGetters('user', [
      'fullName',
    ]),
    task() {
      return this.selectedTask[0];
    },
    disableStart() {
      return this.task.status !== 'assigned';
    },
    disableComplete() {
      return this.task.status !== 'in progress'
      || this.openItems !== 0;
    },
    totalCount() {
      return (this.taskChecklist && this.taskChecklist.totalCount) || 0;
    },
    openItems() {
      return (this.taskChecklist && this.taskChecklist.results
        .filter((d) => d.result === '')
        .length) || 0;
    },
  },
  methods: {
    ...mapMutations('helper', [
      'setAlert',
    ]),
    ...mapActions('maintenanceTask', [
      'updateTaskByTaskId',
      'createRepairs',
    ]),
    async startTask() {
      if (await this.$root.$confirm.open(
        this.$t('maintenanceTask.confirmStartTitle'),
        this.$t('maintenanceTask.confirmStartSubtitle'),
      )) {
        this.startLoading = true;
        await this.updateTaskByTaskId({
          taskId: this.task.id,
          payload: {
            status: 'in progress',
            acturalstarttime: new Date().getTime(),
          },
          listType: 'details',
        });
        this.startLoading = false;
      }
    },
    async validateComplete() {
      const emptySpareparts = this.taskSpareparts.some((e) => !e.quantity);
      if (this.taskSpareparts.length) {
        if (emptySpareparts) {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'EMPTY_SPAREPART',
          });
          this.tab = 1;
          return false;
        }
        const checkstock = this.taskSpareparts.map((replacement) => {
          const sparepartobj = this.taskSpareparts
            .find((item) => item.sparepartid === replacement.sparepartid);
          const { quantity } = replacement;
          const warehouse = {
            warehousecode: sparepartobj.warehousecode,
            warehousename: sparepartobj.warehousename,
          };
          const location = {
            locationcode: sparepartobj.locationcode || '',
            locationname: sparepartobj.locationname || '',
          };
          return WMSService.checkStock(warehouse, location, {
            partname: sparepartobj.sparepartname,
            partnumber: sparepartobj.sparepartcode,
          }, quantity);
        });
        const checkstockresult = await Promise.all(checkstock);
        if (checkstockresult.some((r) => !r)) {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'INVALID_STOCK_QTY',
          });
          this.tab = 1;
          return false;
        }
      }
      return true;
    },
    async completeTask() {
      if (await this.$root.$confirm.open(
        this.$t('maintenanceTask.confirmCompleteTitle'),
        this.$t('maintenanceTask.confirmCompleteSubtitle'),
      )) {
        this.completeLoading = true;
        const isValid = await this.validateComplete();
        if (isValid) {
          await Promise.all([
            this.createSparepartOutbound(),
            this.createRepairTasks(),
          ]);
          await this.updateTaskByTaskId({
            taskId: this.task.id,
            payload: {
              status: 'completed',
              acturalendtime: new Date().getTime(),
            },
            listType: 'details',
          });
        }
        this.completeLoading = false;
      }
    },
    async createSparepartOutbound() {
      if (this.taskSpareparts.length) {
        const outbound = this.taskSpareparts.map(async (replacement) => {
          const sparepartobj = this.taskSpareparts
            .find((item) => item.sparepartid === replacement.sparepartid);
          if (sparepartobj) {
            const { quantity } = replacement;
            const warehouse = {
              warehousecode: sparepartobj.warehousecode,
              warehousename: sparepartobj.warehousename,
            };
            const location = {
              locationcode: sparepartobj.locationcode || '',
              locationname: sparepartobj.locationname || '',
            };
            await WMSService.createOutboundRecord(
              warehouse,
              location,
              {
                partname: sparepartobj.sparepartname,
                partnumber: sparepartobj.sparepartcode,
              },
              {
                key: 5,
                type: 'Consumption',
                description: 'Consumption',
              },
              Number(quantity),
              this.fullName,
              new Date().getTime(),
              0,
            );
          }
        });
        await Promise.all(outbound);
      }
    },
    async createRepairTasks() {
      const nglist = this.taskChecklist.results.filter((item) => item.result === 'NG');
      const payload = nglist.map((item) => ({
        machineid: this.task.machineid,
        machinecode: this.task.machinecode,
        machinename: this.task.machinename,
        faultid: 'tpmfault',
        faultcode: 'D0001',
        faultname: 'TPM project NG',
        faultdescription: 'TPM project NG, automatic generation maintenance task',
        taskid: this.task.id,
        solutiondetailname: item.solutiondetailname,
        solutiondetailvalue: item.value,
        createdby: this.fullName,
        createdtime: new Date().getTime(),
        status: 'new',
        assetid: this.task.assetid,
      }));
      this.createRepairs(payload);
    },
  },
};
</script>

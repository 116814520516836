<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <task-info />
      </v-col>
      <v-col cols="12" lg="6">
        <task-schedule />
      </v-col>
      <v-col cols="12">
        <operator-list />
      </v-col>
      <v-col cols="12">
        <task-reporting />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TaskInfo from './details/TaskInfo.vue';
import TaskSchedule from './details/TaskSchedule.vue';
import OperatorList from './details/OperatorList.vue';
import TaskReporting from './details/TaskReporting.vue';

export default {
  name: 'TaskDetails',
  components: {
    TaskInfo,
    TaskSchedule,
    OperatorList,
    TaskReporting,
  },
};
</script>

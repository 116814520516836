<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="updateTask">
    <v-card
      outlined
      rounded="lg"
    >
      <v-card-title primary-title>
        {{ $t('maintenanceTask.taskSchedule') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('maintenanceTask.taskScheduledStart')"
          dense
          v-model="dateFrom"
          type="datetime-local"
          :disabled="loading || invalidStatus"
          outlined
          :rules="dateFromRules"
        ></v-text-field>
        <v-text-field
          :label="$t('maintenanceTask.taskScheduledEnd')"
          dense
          v-model="dateTo"
          type="datetime-local"
          :disabled="loading || invalidStatus"
          outlined
          :rules="dateToRules"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="my-2">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          class="text-none"
          :disabled="disableApply || !isValid"
          :loading="loading"
        >
          {{ $t('maintenanceTask.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'TaskSchedule',
  data() {
    return {
      isValid: false,
      dateFrom: '',
      dateTo: '',
      loading: false,
      dateFromRules: [() => this.isValidDate()
        || this.$i18n.t('maintenanceTask.validationDateBefore', { date: this.dateTo })],
      dateToRules: [() => this.isValidDate()
        || this.$i18n.t('maintenanceTask.validationDateAfter', { date: this.dateFrom })],
    };
  },
  created() {
    this.dateFrom = formatDate(new Date(this.planstarttime), 'yyyy-MM-dd\'T\'HH:mm');
    this.dateTo = formatDate(new Date(this.planendtime), 'yyyy-MM-dd\'T\'HH:mm');
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask']),
    invalidStatus() {
      return this.task.status === 'in progress'
        || this.task.status === 'completed';
    },
    disableApply() {
      return this.loading
        || !this.dateFrom
        || !this.dateTo
        || this.invalidStatus;
    },
    task() {
      return this.selectedTask[0];
    },
    planstarttime() {
      return this.task.planstarttime;
    },
    planendtime() {
      return this.task.planendtime;
    },
  },
  methods: {
    ...mapActions('maintenanceTask', ['updateTaskByTaskId']),
    isValidDate() {
      let result = true;
      if (this.dateTo && this.dateFrom) {
        const start = new Date(this.dateFrom).getTime();
        const end = new Date(this.dateTo).getTime();
        result = end > start;
      }
      return result;
    },
    async updateTask() {
      this.loading = true;
      await this.updateTaskByTaskId({
        taskId: this.task.id,
        payload: {
          planstarttime: new Date(this.dateFrom).getTime(),
          planendtime: new Date(this.dateTo).getTime(),
          status: 'assigned',
        },
        listType: 'details',
      });
      this.loading = false;
    },
  },
};
</script>

<template>
  <div style="height:100%">
    <portal to="app-header">
      <v-btn class="mb-1" icon @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>{{ id }}</span>
    </portal>
    <v-container fill-height v-if="loading">
      <v-row
        align="center"
        justify="center"
        :no-gutters="$vuetify.breakpoint.smAndDown"
      >
        <v-col cols="12" align="center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="72"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" align="center">
          <div class="headline">
            {{ $t('maintenanceTask.loadingTitle') }}
          </div>
          <div class="title">
            {{ $t('maintenanceTask.fetchingTask') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <task-not-found v-else-if="!loading && error" />
    <task-details v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TaskNotFound from '../components/TaskNotFound.vue';
import TaskDetails from '../components/TaskDetails.vue';

export default {
  name: 'MaintenanceTaskDetails',
  components: {
    TaskNotFound,
    TaskDetails,
  },
  data() {
    return {
      error: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask']),
    id() {
      return this.$route.params.id;
    },
  },
  async created() {
    this.loading = true;
    this.error = false;
    const task = await this.fetchTask(this.id);
    this.error = !task;
    this.loading = false;
  },
  methods: {
    ...mapActions('maintenanceTask', ['fetchTask']),
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>
  <a @click="goToTask" class="text-decoration-underline">
    {{ plan.planname }}
  </a>
</template>

<script>
export default {
  name: 'TaskIdTemplate',
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToTask() {
      this.$router.push({
        name: 'maintenanceTaskDetails',
        params: { id: this.plan.id },
      });
    },
  },
};
</script>

<template>
  <div style="height: 100%">
    <maintenance-task-loading v-if="loading" />
    <template v-else>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import MaintenanceTaskLoading from './MaintenanceTaskLoading.vue';

export default {
  name: 'MaintenanceTaskIndex',
  components: {
    MaintenanceTaskLoading,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('webApp', ['config', 'storageLocation']),
  },
  async created() {
    this.loading = true;
    const config = localStorage.getItem(this.storageLocation.maintenanceTask);
    if (config) {
      this.setConfig(JSON.parse(config));
    } else {
      this.resetConfig();
    }
    await Promise.all([
      this.fetchMachines(),
      this.fetchSolutions(),
      this.fetchAssets(),
    ]);
    this.loading = false;
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapActions('maintenanceTask', [
      'fetchMachines',
      'fetchSolutions',
      'fetchAssets',
    ]),
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        localStorage.setItem(this.storageLocation.maintenanceTask, JSON.stringify(val));
      },
    },
  },
};
</script>

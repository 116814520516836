<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndAddNew)">
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      filled
                      disabled
                      :label="$t('maintenanceTask.taskType')"
                      v-model="task.type"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <validation-provider
                      name="taskname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        clearable
                        filled
                        :label="$t('maintenanceTask.taskName')"
                        :error-messages="errors"
                        v-model="task.planname"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('maintenanceTask.machine')"
                        :items="machineList"
                        :error-messages="errors"
                        :disabled="saving"
                        item-text="name"
                        item-value="id"
                        v-model="task.machineid"
                        @change="onMachineSelection"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="solutionname"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-autocomplete
                        clearable
                        filled
                        :label="$t('maintenanceTask.solution')"
                        :items="solutionList"
                        :error-messages="errors"
                        :disabled="saving"
                        item-text="name"
                        item-value="id"
                        v-model="task.solutionid"
                        @change="onSolutionSelection"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('maintenanceTask.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="task.planstarttime"
                        :disabled="saving"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('maintenanceTask.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="primary"
                  class="text-none"
                  :disabled="savingAndNew"
                  :loading="savingAndExit"
                  @click="onSaveAndExit"
                >
                  {{ $t('maintenanceTask.saveExit') }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-none"
                  :disabled="savingAndExit"
                  :loading="savingAndNew"
                  type="submit"
                >
                  {{ $t('maintenanceTask.saveAddNew') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'AddTask',
  data() {
    return {
      savingAndExit: false,
      savingAndNew: false,
      task: null,
    };
  },
  computed: {
    ...mapGetters('maintenanceTask', [
      'machineList',
      'solutionList',
    ]),
    ...mapGetters('user', [
      'fullName',
    ]),
    saving() {
      return this.savingAndExit || this.savingAndNew;
    },
  },
  created() {
    this.initTask();
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('maintenanceTask', [
      'createTask',
      'fetchNewlyAddedTask',
      'fetchMachineOperators',
      'fetchSolutionDetails',
      'createTaskDetails',
      'createTaskOperators',
    ]),
    initTask() {
      this.task = {
        assetid: 0,
        machinecode: '',
        machineid: '',
        machinename: '',
        planname: '',
        planid: 'plan-0',
        solutionid: '',
        solutionname: '',
        solutiontype: '',
        type: 'PM',
        status: 'new',
        planstarttime: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
      };
    },
    clear() {
      this.initTask();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    onMachineSelection(machineId) {
      if (machineId) {
        const machine = this.machineList.find((m) => m.id === machineId);
        const {
          id,
          name,
          machinecode,
          assetid,
        } = machine;
        this.task.machineid = id;
        this.task.machinename = name;
        this.task.machinecode = machinecode;
        this.task.assetid = assetid;
      } else {
        this.task.machineid = '';
        this.task.machinename = '';
        this.task.machinecode = '';
        this.task.assetid = 0;
      }
    },
    onSolutionSelection(solutionId) {
      if (solutionId) {
        const solution = this.solutionList.find((m) => m.id === solutionId);
        const { id, name, type } = solution;
        this.task.solutionid = id;
        this.task.solutionname = name;
        this.task.solutiontype = type;
      } else {
        this.task.solutionid = '';
        this.task.solutionname = '';
        this.task.solutiontype = '';
      }
    },
    exit() {
      this.clear();
      this.$router.push({ name: 'maintenanceTask' });
    },
    async save() {
      const startTime = new Date(this.task.planstarttime).getTime();
      const payload = {
        ...this.task,
        createdtime: new Date().getTime(),
        createdby: this.fullName,
        planstarttime: startTime,
        planendtime: startTime + 86400000,
        plandate: formatDate(startTime, 'yyyyMMdd'),
      };
      const created = await this.createTask(payload);
      if (created) {
        const task = await this.fetchNewlyAddedTask();
        if (task && task.length) {
          const solutionDetails = await this.fetchSolutionDetails(this.task.solutionid);
          const operators = await this.fetchMachineOperators(this.task.machineid);
          const taskDetailsPayload = solutionDetails.map((s) => ({
            taskid: task[0].id,
            solutionid: s.solutionid,
            solutionname: s.solutionname,
            solutiondetailname: s.name,
            description: s.description,
            group: s.group,
            islimited: s.islimited,
            lower: s.lower,
            upper: s.upper,
            type: s.type,
            image: s.image,
            result: '',
            value: '',
            assetid: 0,
          }));
          const taskOperatorsPayload = operators.map((o) => ({
            taskid: task[0].id,
            planid: 'plan-0',
            planname: this.task.planname,
            operatorid: o.operatorid,
            operatorname: o.operatorname,
            assetid: 0,
          }));
          const success = await Promise.all([
            this.createTaskDetails(taskDetailsPayload),
            this.createTaskOperators(taskOperatorsPayload),
          ]);
          if (success && success.every((s) => s)) {
            this.setAlert({
              show: true,
              type: 'success',
              message: 'TASK_CREATED',
            });
          } else {
            this.setAlert({
              show: true,
              type: 'error',
              message: 'ERROR_CREATING_TASK',
            });
          }
        } else {
          this.setAlert({
            show: true,
            type: 'error',
            message: 'ERROR_CREATING_TASK',
          });
        }
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'ERROR_CREATING_TASK',
        });
      }
      return created;
    },
    async onSaveAndAddNew() {
      this.savingAndNew = true;
      const created = await this.save();
      if (created) {
        this.clear();
      }
      this.savingAndNew = false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.savingAndExit = true;
        const created = await this.save();
        if (created) {
          this.exit();
        }
        this.savingAndExit = false;
      }
    },
  },
};
</script>

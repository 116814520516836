<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-avatar
        size="16"
        class="mb-1"
        v-bind="attrs"
        v-on="on"
        :color="getTaskStatus(status).color"
      ></v-avatar>
    </template>
    {{ $t(`maintenanceTask.${getTaskStatus(status).text}`) }}
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TaskStatusTemplate',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('maintenanceTask', ['taskStatus']),
  },
  methods: {
    getTaskStatus(status) {
      return this.taskStatus(status);
    },
  },
};
</script>

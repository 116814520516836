<template>
  <v-card
    flat
    :class="isDark ? 'secondary' : 'primary'"
    dark
    rounded="lg"
  >
    <v-card-title primary-title>
      {{ taskName }}
      <v-spacer></v-spacer>
      <v-chip
        label
        :color="getTaskStatus(status).color"
      >
        {{ $t(`maintenanceTask.${getTaskStatus(status).text}`) }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenanceTask.machine') }}
          </div>
          <div class="title white--text">
            {{ machine }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenanceTask.type') }}
          </div>
          <div class="title warning--text">
            {{ type }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenanceTask.createdAt') }}
          </div>
          <div class="title white--text">
            {{ createdAt }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenanceTask.taskStartTime') }}
          </div>
          <div class="title white--text">
            {{ actualStart }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            {{ $t('maintenanceTask.taskEndTime') }}
          </div>
          <div class="title white--text">
            {{ actualEnd }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="8">
          <div>
            {{ $t('maintenanceTask.checklist') }}
          </div>
          <div class="title white--text">
            {{ checklist }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'TaskInfo',
  inject: ['theme'],
  data() {
    return {
      adherenceInfo: null,
    };
  },
  computed: {
    ...mapState('maintenanceTask', ['selectedTask']),
    ...mapGetters('maintenanceTask', ['taskStatus']),
    ...mapGetters('helper', ['locale']),
    task() {
      return this.selectedTask[0];
    },
    isDark() {
      return this.theme.isDark;
    },
    status() {
      return this.task.status;
    },
    taskName() {
      return this.task.planname;
    },
    machine() {
      return this.task.machinename;
    },
    type() {
      return this.task.type;
    },
    checklist() {
      return this.task.solutionname;
    },
    count() {
      return this.task.duration;
    },
    cronName() {
      return this.task.cronname;
    },
    cron() {
      return this.task.cron;
    },
    createdAt() {
      return this.getFormattedDate(this.task.createdtime);
    },
    createdBy() {
      return this.task.createdby;
    },
    actualStart() {
      return this.task.acturalstarttime
        ? this.getFormattedDate(this.task.acturalstarttime)
        : '-';
    },
    actualEnd() {
      return this.task.acturalendtime
        ? this.getFormattedDate(this.task.acturalendtime)
        : '-';
    },
  },
  methods: {
    ...mapActions('maintenanceTask', ['fetchTaskAdherence']),
    getTaskStatus(status) {
      return this.taskStatus(status);
    },
    getFormattedDate(datetime) {
      return formatDate(
        new Date(datetime),
        'MMM d, HH:mm',
        { locale: this.locale },
      );
    },
  },
};
</script>

<template>
  <v-data-table
    :items="items"
    :headers="headers"
    hide-default-footer
    disable-pagination
    item-key="_id"
  >
    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <task-status-template :status="item.status" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.planname="{ item }">
      <task-id-template :plan="item" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.planstarttime="{ item }">
      <task-start-template :datetime="item.planstarttime" />
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.acturalstarttime="{ item }">
      <task-start-template
        v-if="item.acturalstarttime"
        :datetime="item.acturalstarttime"
      />
      <span v-else>
        -
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.acturalendtime="{ item }">
      <task-start-template
        v-if="item.acturalendtime"
        :datetime="item.acturalstarttime"
      />
      <span v-else>
        -
      </span>
    </template>
  </v-data-table>
</template>

<script>
import TaskStatusTemplate from './TaskStatusTemplate.vue';
import TaskIdTemplate from './TaskIdTemplate.vue';
import TaskStartTemplate from './TaskStartTemplate.vue';

export default {
  name: 'MachineView',
  components: {
    TaskStatusTemplate,
    TaskIdTemplate,
    TaskStartTemplate,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: '', value: 'status' },
        {
          text: this.$t('maintenanceTask.plan'),
          value: 'planname',
          width: '30%',
        },
        { text: this.$t('maintenanceTask.type'), value: 'type' },
        { text: this.$t('maintenanceTask.checklist'), value: 'solutionname' },
        { text: this.$t('maintenanceTask.planstarttime'), value: 'planstarttime' },
        { text: this.$t('maintenanceTask.taskStartTime'), value: 'acturalstarttime' },
        { text: this.$t('maintenanceTask.taskEndTime'), value: 'acturalendtime' },
      ],
    };
  },
};
</script>

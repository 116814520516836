<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        small
        class="text-none"
        color="primary"
        :disabled="selectedTask[0].status !== 'in progress'"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t('maintenancePlan.addSparepart') }}
      </v-btn>
    </template>
    <v-form ref="form" v-model="isValid" @submit.prevent="addNew">
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          {{ $t('maintenancePlan.addSparepartTitle') }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :loading="loading"
            outlined
            class="mt-1"
            :label="$t('maintenancePlan.sparepartName')"
            :items="parts"
            :rules="[(v) => !!v || $t('maintenancePlan.required')]"
            :disabled="saving"
            item-text="sparepartname"
            item-value="sparepartid"
            v-model="item.sparepartid"
          >
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.sparepartname"></v-list-item-title>
                <v-list-item-subtitle v-text="item.sparepartcode"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="item.machinepositionname"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            outlined
            :label="$t('maintenanceTask.quantity')"
            :disabled="saving"
            :rules="[(v) => !!v || $t('maintenancePlan.required')]"
            v-model.number="item.quantity"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text :disabled="saving" class="text-none" @click="cancel">
            {{ $t('maintenancePlan.exit') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="primary"
            class="text-none"
            :loading="saving"
            :disabled="!isValid"
          >
            {{ $t('maintenancePlan.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AddSparepart',
  data() {
    return {
      item: null,
      parts: [],
      isValid: false,
      dialog: false,
      loading: false,
      saving: false,
    };
  },
  created() {
    this.initItem();
  },
  computed: {
    ...mapState('maintenanceTask', [
      'selectedTask',
    ]),
  },
  methods: {
    ...mapActions('maintenanceTask', [
      'fetchMachineSpareParts',
      'addSparepart',
    ]),
    initItem() {
      this.item = {
        sparepartid: '',
        quantity: 0,
        assetid: 0,
      };
    },
    cancel() {
      this.initItem();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      this.dialog = false;
    },
    async addNew() {
      this.saving = true;
      const { id, planname } = this.selectedTask[0];
      const sparePart = this.parts.find((p) => p.sparepartid === this.item.sparepartid);
      const {
        sparepartname,
        sparepartcode,
        warehousecode,
        warehousename,
        machineid,
        machinepositionid,
        machinepositionname,
      } = sparePart;
      this.item.taskid = id;
      this.item.planname = planname;
      this.item.sparepartname = sparepartname;
      this.item.sparepartcode = sparepartcode;
      this.item.warehousecode = warehousecode;
      this.item.warehousename = warehousename;
      this.item.machineid = machineid;
      this.item.machinepositionid = machinepositionid;
      this.item.machinepositionname = machinepositionname;
      const added = await this.addSparepart(this.item);
      if (added) {
        this.cancel();
      }
      this.saving = false;
    },
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.loading = true;
        this.parts = await this.fetchMachineSpareParts(this.selectedTask[0].machineid);
        this.loading = false;
      }
    },
  },
};
</script>

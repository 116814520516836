<template>
  <v-navigation-drawer
    app
    right
    clipped
    width="290"
    v-model="model"
  >
    <perfect-scrollbar style="height: 100%">
      <div class="mt-4 mx-2" >
        <calendar-date-selection v-if="isCalendarView" />
        <date-selection v-else />
        <machine-selection />
        <type-selection />
        <status-selection />
        <group-selection v-if="!isCalendarView" />
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import CalendarDateSelection from './filters/CalendarDateSelection.vue';
import DateSelection from './filters/DateSelection.vue';
import MachineSelection from './filters/MachineSelection.vue';
import StatusSelection from './filters/StatusSelection.vue';
import TypeSelection from './filters/TypeSelection.vue';
import GroupSelection from './filters/GroupSelection.vue';

export default {
  name: 'TaskDrawer',
  components: {
    CalendarDateSelection,
    DateSelection,
    MachineSelection,
    StatusSelection,
    TypeSelection,
    GroupSelection,
  },
  computed: {
    ...mapState('maintenanceTask', ['drawer']),
    ...mapGetters('maintenanceTask', ['isCalendarView']),
    model: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  methods: {
    ...mapMutations('maintenanceTask', ['setDrawer']),
  },
};
</script>
